<template>
  <div class="hint-wrapper">
    <ClientOnly>
      <CdeCard
        :max-width="smAndDown ? '100vw' : '340'"
        class="mr-0 ml-auto py-3 py-md-7 px-1 px-md-5 hint-card"
        :rounded="!smAndDown ? 'lg' : false">
        <v-row v-if="!smAndDown" dense>
          <v-col cols="5" sm="4" class="pa-0">
            <v-img src="@/assets/icons/abholung.svg" height="60px" :alt="t('delivery')" />
          </v-col>
          <v-col cols="7" sm="8" class="font-weight-bold pa-0">
            <span>
              {{ labelArray[0] }}
              <span class="color-me">{{ tomorrowDate.long }}</span>
              {{ labelArray[1] }}
            </span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            :class="{ 'px-0 pb-0 pt-4 font-weight-medium': true, 'text-center': smAndDown }">
            <span v-if="!smAndDown">{{ hintText }}</span>
            <span v-if="smAndDown" class="font-weight-bold hint-text">
              {{ hintMobileText }}
              <span class="color-me">{{ tomorrowDate.dateOnly }}</span>
            </span>
          </v-col>
        </v-row>
      </CdeCard>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
const { smAndDown } = useDisplay()
const props = defineProps<{
  hintLabel: string | null
  hintText: string | null
  hintMobileText: string | null
}>()
const { hintLabel, hintText } = props
const labelArray = hintLabel?.split('morgen') || []
const { getEarliestPossibleDeliveryDate } = useDateUtils()
const earliestDeliveryDate = ref(new Date())
const { t } = useI18n()

onNuxtReady(() => {
  getEarliestPossibleDeliveryDate().then(date => {
    earliestDeliveryDate.value = date
  })
})
const tomorrowDate = computed(() => {
  const tomorrow = earliestDeliveryDate.value.toLocaleString('de-DE', {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  const tomorrowDateOnly = earliestDeliveryDate.value.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  return { long: tomorrow, dateOnly: tomorrowDateOnly }
})
</script>
<style lang="scss" scoped>
.hint-wrapper {
  position: relative;
}
.hint-card {
  position: absolute;
  bottom: 30%;
  right: 10%;
  line-height: 22px;
}
.color-me {
  color: rgb(var(--v-theme-secondary));
}
@media (max-width: 959px) {
  .hint-card {
    position: relative;
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 449px) {
  .hint-text {
    font-size: 12px;
  }
}
@media (max-width: 380px) {
  .hint-text {
    font-size: 11px;
  }
}
</style>
